import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  BoltIcon,
  BugAntIcon,
  BookOpenIcon,
  ClipboardDocumentIcon,
  ListBulletIcon,
  CodeBracketSquareIcon,
  CheckBadgeIcon,
  WrenchScrewdriverIcon,
  PencilSquareIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { compact, uniqBy } from "lodash";

const backgroundColors = ["ontrack", "inprogress", "at-risk"];
const sortOrder = {
  Epic: 1,
  Story: 2,
  Task: 3,
  "Sub Task": 4,
  Bug: 5,
};

const TeamSummary = ({ allType, data }) => {
  const [epics, setEpics] = useState([]);
  const [summary, setSummary] = useState([]);
  const [status, setStatus] = useState([]);
  useEffect(() => {
    const uniqueEpics = compact(
      uniqBy(
        data.map((i) => ({
          name: i?.fields?.parent?.fields?.summary,
          key: i?.fields?.parent?.key,
        })),
        (item) => item.name
      )
    );

    setEpics(uniqueEpics);
  }, [data]);

  useEffect(() => {
    if (!allType?.length) return;

    const issueCounts = allType
      .map((i) => i?.fields?.issuetype?.name)
      .reduce((acc, cur) => {
        acc[cur] = acc[cur] + 1 || 1;
        return acc;
      }, {});

    const epicCount = epics.length;
    const updatedSummary = Object.entries(issueCounts)
      .map(([issueType, count]) => ({
        issueType,
        count: issueType === "Epic" && epicCount > count ? epicCount : count,
      }))
      .sort((a, b) => sortOrder[a.issueType] - sortOrder[b.issueType]);

    setSummary(updatedSummary);

    const statusCounts = allType
      .map((i) => i?.fields?.status?.name)
      .reduce((acc, cur) => {
        acc[cur] = acc[cur] + 1 || 1;
        return acc;
      }, {});

    setStatus(statusCounts);
  }, [allType, epics]);

  const getIconForIssueType = (issueType) => {
    switch (issueType) {
      case "Bug":
        return <BugAntIcon />;
      case "Epic":
        return <BoltIcon />;
      case "Task":
        return <ClipboardDocumentIcon />;
      case "Story":
      case "To Do":
        return <BookOpenIcon />;
      case "Implementing":
        return <CodeBracketSquareIcon />;
      case "Done":
      case "Ready To Code Review":
      case "Ready For Test":
      case "Ready for Build":
      case "Approved for Deploy":
        return <CheckBadgeIcon />;
      case "Testing":
      case "In Testing":
      case "In Build":
        return <WrenchScrewdriverIcon />;
      case "In Review":
        return <PencilSquareIcon />;
      case "Rejected":
      case "Deployed, Not Complete":
        return <XCircleIcon />;
      default:
        return <ListBulletIcon />;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="summary dashboard-wrapper team-dashboard">
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <div className="summary-item">
              <h4>Summary</h4>
              <div className="detail-item no-border flex">
                {summary.map((item, index) => (
                  <div
                    className={`item ${
                      backgroundColors[index % backgroundColors.length]
                    }`}
                    key={index}
                  >
                    {getIconForIssueType(item.issueType)}
                    <p>{item.issueType}</p>
                    <h3>
                      {item.issueType === "Epic" && epics.length > item.count
                        ? epics.length
                        : item.count}
                    </h3>
                  </div>
                ))}
              </div>
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <div className="summary-item">
              <h4>On Going Projects</h4>
              <div className="detail-item no-border flex scroll">
                <table>
                  <tbody>
                    {epics.length > 0 &&
                      epics?.map((project, index) => (
                        <tr key={index}>
                          <td>
                            <p>
                              <a
                                href={`https://lotusretails.atlassian.net/browse/${project.key}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {project.name}
                              </a>
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="summary-item">
              <h4>Status</h4>
              <div className="detail-item no-border flex">
                {Object.entries(status).map(([status, count], index) => (
                  <div
                    className={`item ${
                      backgroundColors[index % backgroundColors.length]
                    }`}
                    key={status}
                  >
                    {getIconForIssueType(status)}
                    <p>{status}</p>
                    <h3>{count}</h3>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default TeamSummary;
