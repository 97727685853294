import React from "react";
import Seo from "../../components/seo";
import LocalDevTeam from "../../components/Teams/LocalDev/LocalDev";
import queryString from "query-string";
const LocalDevTeamPage = (props) => {
  const { search } = props.location;
  const { team } = queryString.parse(search);

  return (
    <>
      <Seo title="Local Dev Team" />
      <LocalDevTeam {...props} team={team} />
    </>
  );
};

export default LocalDevTeamPage;
