import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { searchJira } from "../httpRequests/jira";

// Predefined date ranges
const dateRanges = {
  thisMonth: {
    label: "This Month",
    from: dayjs().startOf("month"),
    to: dayjs().endOf("month"),
  },
  prevMonth: {
    label: "Previous Month",
    from: dayjs().subtract(1, "month").startOf("month"),
    to: dayjs().subtract(1, "month").endOf("month"),
  },
  thisWeek: {
    label: "This Week",
    from: dayjs().startOf("week"),
    to: dayjs().endOf("week"),
  },
  prevWeek: {
    label: "Previous Week",
    from: dayjs().subtract(1, "week").startOf("week"),
    to: dayjs().subtract(1, "week").endOf("week"),
  },
};

const JiraDateRange = ({
  board,
  setData,
  setLoading,
  setTotalResults,
  setAllType,
  pagination,
}) => {
  const [time, setTime] = useState("thisMonth");
  const [from, setFrom] = useState(
    dateRanges["thisMonth"].from.format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(dateRanges["thisMonth"].to.format("YYYY-MM-DD"));
  const [ticketType, setTicketType] = useState(["Story"]);
  const [error, setError] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError("");

    const jql = `issueType IN (${ticketType}) AND (updated >= '${from}' and updated < '${to}') AND (project in (${board}))`;

    try {
      const response = await searchJira({
        jql: `updated >= '${from}' and updated < '${to}' AND (project in (${board}))`,
        fields:
          "summary, worklog, issuetype, parent, project, status, assignee",
        maxResults: 100,
        startAt: 0,
      });

      setAllType(response.data.issues);

      const res = await searchJira({
        jql,
        fields:
          "summary, worklog, issuetype, parent, project, status, assignee",
        maxResults: pagination.pageSize,
        startAt: pagination.pageIndex * pagination.pageSize,
      });

      setData(res.data.issues);
      setTotalResults(res.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Jira data:", error);
      setError("Failed to fetch data. Please try again.");
      setLoading(false);
    }
  }, [from, to, ticketType, board, pagination, setData, setLoading]);

  useEffect(() => {
    if (board) {
      fetchData();
    }
  }, [board, pagination, fetchData]);

  const handleRangeChange = (e) => {
    const selectedRange = e.target.value;
    setTime(selectedRange);

    if (selectedRange !== "custom") {
      const { from, to } = dateRanges[selectedRange];
      setFrom(from.format("YYYY-MM-DD"));
      setTo(to.format("YYYY-MM-DD"));
    }
  };

  const handleTicketTypeChange = (e) => setTicketType(e.target.value);

  return (
    <Box className="search-container">
      <h5>Select Ticket Type and Date Range</h5>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <Select
              value={ticketType}
              size="small"
              onChange={handleTicketTypeChange}
              multiple
            >
              {["Story", "Task", "Bug"].map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox checked={ticketType.includes(type)} />
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <Select value={time} size="small" onChange={handleRangeChange}>
              {Object.keys(dateRanges).map((key) => (
                <MenuItem key={key} value={key}>
                  {dateRanges[key].label}
                </MenuItem>
              ))}
              <MenuItem value="custom">Custom Range</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {time === "custom" && (
          <>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  inputFormat="YYYY-MM-DD"
                  value={dayjs(from)}
                  onChange={(newValue) =>
                    setFrom(newValue.format("YYYY-MM-DD"))
                  }
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      placeholder="Select start date"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  inputFormat="YYYY-MM-DD"
                  value={dayjs(to)}
                  onChange={(newValue) => setTo(newValue.format("YYYY-MM-DD"))}
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      placeholder="Select end date"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            fullWidth
            className="search-bt"
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JiraDateRange;
