import React, { useMemo, useState } from "react";
import { Container } from "@mui/material";
import DEV_TEAMS from "../../../constants/teams";
import { Wrapper } from "./styles";
import JiraDateRange from "../../../shared/JiraDateRange";
import Loading from "../../../shared/Loading";
import TeamTaskDetail from "../../../shared/TeamTaskDetail";
import TeamSummary from "../../../shared/TeamSummary";

const LocalDevTeam = ({ team }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [allType, setAllType] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const teamData = useMemo(
    () => DEV_TEAMS.filter((i) => i.team === team),
    [team]
  );

  const { teamName, areas, sdm, board } = teamData[0] || {};

  return (
    <Container>
      {teamData.length === 0 ? (
        <Loading />
      ) : (
        <Wrapper>
          <h1>{teamName}</h1>
          <p>{areas}</p>
          <p>SDM: {sdm}</p>

          <JiraDateRange
            board={board}
            setTotalResults={setTotalResults}
            setData={setData}
            setLoading={setLoading}
            setAllType={setAllType}
            pagination={pagination}
          />

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {allType.length > 0 && (
                <TeamSummary allType={allType} data={data} />
              )}
              <TeamTaskDetail
                data={data}
                isLoading={isLoading}
                totalResults={totalResults}
                setPagination={setPagination}
                pagination={pagination}
              />
            </>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default LocalDevTeam;
