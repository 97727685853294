import React, { useMemo } from "react";
import { Box } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const TeamTaskDetail = ({
  data,
  totalResults,
  pagination,
  setPagination,
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "fields.parent.fields.summary",
        header: "Epic",
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <a
              href={`https://lotusretails.atlassian.net/browse/${row.original.key}`}
              target="_blank"
              rel="noreferrer"
            >
              {renderedCellValue}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: "fields.summary",
        header: "Name",
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <a
              href={`https://lotusretails.atlassian.net/browse/${row.original.key}`}
              target="_blank"
              rel="noreferrer"
            >
              {renderedCellValue}
            </a>
          </Box>
        ),
      },
      { accessorKey: "fields.assignee.displayName", header: "Assignee" },
      { accessorKey: "fields.status.name", header: "Status" },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableEditing: false,
    rowCount: totalResults,
    manualPagination: true,
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { pagination: { pageSize: 25, pageIndex: 0 } },
  });
  return (
    <div className="task-table">
      <h3>Task Detail</h3>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default TeamTaskDetail;
