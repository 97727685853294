import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 80px;
  h1 {
    margin-bottom: 10px;
  }
  .search-container {
    margin: 40px 0 30px;
    button {
      &.search-bt {
        color: #fff;
      }
    }
  }
  .summary {
    margin-bottom: 30px;
    .scroll {
      height: 100%;
      max-height: 180px;
      overflow: scroll;
      table {
        td {
          border-bottom: 0;
          padding: 3px 5px !important;
        }
        p {
          font-size: 13px;
          a {
            color: #000;
            &:hover {
              color: #0071cd;
            }
          }
        }
      }
    }
    .item {
      max-width: 120px !important;
      p {
        line-height: 125%;
        margin-bottom: 10px;
      }
      svg {
        margin-top: 10px;
        width: 30px;
      }
    }
  }
  .pie-chart {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  .task-table {
    table {
      tr,
      th {
        &:first-child {
          padding-left: 15px;
        }
      }
      a {
        color: #000;
      }
    }
  }
`;
